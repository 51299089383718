<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h3 class="mt-3">Elementos Escaneados: {{ arrayCodes.length }}</h3>
        <!-- <v-btn color="primary" @click="assign(tucaneCode, anukinCode)">asignar</v-btn> -->
      </v-col>
      <v-col cols="6" class="mt-3">
        <v-btn class="white--text" color="black" @click="assingAlltoPixels" :disabled="arrayCodes.length == 0">Vincular Todos</v-btn>
        <v-btn class="ml-6" color="error" @click="cleanArray" :disabled="arrayCodes.length == 0">Eliminar Todos</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field v-on:keyup.enter= checkSku() ref="tucane" v-model="tucaneCode" color="black" label="Código Tucané" shaped outlined />
      </v-col>
      <v-col cols="6">
        <v-text-field v-on:keyup.enter=addToArrayCodes() ref="anukin" v-model="anukinCode" color="black" label="Código Anukin" shaped outlined />
      </v-col>
      
      <v-col cols="12" class="d-flex justify-center align-center" v-if="showLoading == true">
        <v-progress-circular indeterminate color="black" size="70" width="7" class="mb-7"/>
      </v-col>

    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Código Tucane</th>
                <th class="text-left">Código Anukin</th>
                <th class="text-left">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr v-for="(item, index) in arrayCodes" :key="item.anukinCode"> -->
              <tr v-for="(item, index) in arrayCodes" :key="item.anukinCode">
                <td>{{ item.tucaneCode }}</td>
                <td>{{ item.anukinCode }}</td>
                <td><v-btn small color="error" @click="deleteFromArray(index)">Eliminar</v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

      <v-snackbar v-model="snackbar" :timeout="timeout">Código inválido</v-snackbar>

  </v-container>
</template>

<script>
import router from '../router/index'
import { mapState } from 'vuex';
import Swal from 'sweetalert2'
import axios from "axios";
import { nextTick } from 'process'

export default {
  data() {
    return {
      tucaneCode: '',
      anukinCode: '',
      arrayCodes: [],

      showLoading: false,

      //Snackbar
      snackbar: false,
      timeout: 2000,
    }
  },
  computed: {
    ...mapState(['catalog']),
  },

  methods: {
    async assign(tucane, anukin) {
      const myObj = {
        tucaneCode: tucane,
        anukinCode: anukin
      }
      this.arrayCodes.unshift(myObj)
    },

    deleteFromArray(index) {
      this.arrayCodes.splice(index, 1);
      this.$refs.tucane.focus();
    },

    cleanArray () {
      this.arrayCodes = []
      this.$refs.tucane.focus();
      nextTick(() => {
          this.anukinCode = ''
          this.tucaneCode = ''
      })
    },

    async assingAlltoPixels () {
      try {
        this.showLoading = true
        //const response = await axios.post('http://localhost:3300/assignation', {
        const response = await axios.post('https://tucane-mqtt-client.herokuapp.com/assignation', {
          toAssign: this.arrayCodes
        }) 
        console.log(response)
        //console.log(response.status)
        this.showLoading = false
        
        const errorsArray = response.data.data.filter(e => e.status != 200)
        if(response.status == 200 && errorsArray == 0){
          Swal.fire({
            //position: 'top-end',
            icon: 'success',
            title: 'Todos los elementos vinculados correctamente',
            showConfirmButton: true,
            //timer: 1800,
            confirmButtonColor: 'black',
          })
        } else {
          let itemHtml = ''
          errorsArray.forEach(item => {
            itemHtml += `<tr><td>${item.anukinCode}</td><td>${item.status}</td><td>${item.message}</td></tr>`
          })
          //console.log(itemHtml)
          const myHtml = `<table style="width:100%"><tr><th>Anukin</th><th>Código</th><th>Mensaje</th></tr>${itemHtml}</table>`
          //console.log(myHtml)
          Swal.fire({
            //position: 'top-end',
            icon: 'warning',
            title: 'Error en los siguientes elementos: ',
            html: myHtml,
            //text: JSON.stringify(response.data.data),
            showConfirmButton: true,
            //timer: 1800
            grow: 'row',
            confirmButtonColor: 'black',
          })
        }
        this.cleanArray()
        router.push('/')
      } catch (error) {
        console.log(error)
      }  
    },

    addToArrayCodes(){
      const anukinCodeFix = this.anukinCode.substring(22)
      if(!this.arrayCodes.some(item => item.anukinCode == anukinCodeFix)){
        console.log(anukinCodeFix)
        this.assign(this.tucaneCode, anukinCodeFix)
        this.$refs.tucane.focus();
        nextTick(() => {
          this.anukinCode = ''
          this.tucaneCode = ''
        })
      }
        else {
          this.snackbar = true
          this.$refs.tucane.focus();
          nextTick(() => {
            this.anukinCode = ''
            this.tucaneCode = ''
          })
        }
    },

    checkSku (){
      if(this.catalog.some(item => item.sku == this.tucaneCode)){
        console.log(this.tucaneCode)
        this.$refs.anukin.focus()
      } else {
        this.snackbar = true
        this.$refs.tucane.focus();
        nextTick(() => {
          this.anukinCode = ''
          this.tucaneCode = ''
        })
      }

    }
  
  },

  mounted() {
    this.$refs.tucane.focus();
  },
}
</script>